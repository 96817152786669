<template>
<div >

  <el-table
    :data="planData"
    border
    style=";margin-left: 5%;margin-top: 50px">
    <el-table-column
      fixed
      prop="stationName"
      label="服务站"
      width="190">
    </el-table-column>
    <el-table-column
      prop="area"
      label="开阀面积(m²)"
      width="120">
    </el-table-column>
    <el-table-column
      prop="waterPlan"
      label="水(kg/m²)"
      width="120">
    </el-table-column>
    <el-table-column
      prop="powerPlan"
      label="电(m²)"
      width="120">
    </el-table-column>
    <el-table-column
      prop="heatPlan"
      label="热(m²/GJ)"
      width="120">
    </el-table-column>
    <el-table-column
      prop="heatPlan2"
      label="热(GJ/m²)"
      width="120">
    </el-table-column>
    <el-table-column
      prop="stay"
      label="入住率"
      width="120">
    </el-table-column>
    <el-table-column
      prop="updateTime"
      label="更新日期"
      width="300">
    </el-table-column>
    <el-table-column
      label="操作"
      width="100">
      <template slot-scope="scope">
        <el-button @click="editPlan(scope.row)" type="text" size="small">更新</el-button>
      </template>
    </el-table-column>
  </el-table>

  <el-dialog :title="编辑计划" :visible.sync="Visible" :append-to-body="true" width="60%" top="calc((100vh - 600px) / 2)" :center=true :close-on-click-modal=false>
    <el-form label-width="90px">
      <el-row>
        <el-col :xs=24 :sm=12>
          <el-form-item label="机组名称">
            <el-input readonly="true" v-model="plan.stationName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs=24 :sm=12>
          <el-form-item label="水指标">
            <el-input v-model="plan.waterPlan"></el-input>
          </el-form-item>
        </el-col>

        <el-col :xs=24 :sm=12>
          <el-form-item label="电指标">
            <el-input v-model="plan.powerPlan"></el-input>
          </el-form-item>
        </el-col>

        <el-col :xs=24 :sm=12>
          <el-form-item label="热指标">
            <el-input v-model="plan.heatPlan"></el-input>
          </el-form-item>
        </el-col>

        <el-col :xs=24 :sm=12>
          <el-form-item label="实供面积">
            <el-input v-model="plan.area"></el-input>
          </el-form-item>
        </el-col>

        <el-col :xs=24 :sm=12>
          <el-form-item label="入住率">
            <el-input v-model="plan.stay"></el-input>
          </el-form-item>
        </el-col>

      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="savePlan">确定更新</el-button>
        <el-button type="danger" @click="Visible = false">取消</el-button>
      </span>
  </el-dialog>
</div>
</template>

<script>
import clientUtils from "@/js/clientUtils";

export default {
  name: "Plan",
  data(){
    return{
      planData:[],
      Visible: false,
      plan:{}
    }
  },
  mounted() {
   this.getPlan()
  },
  methods:{
    //点击编辑弹出dialog
    editPlan: function (plan) {
      this.Visible = true;
      this.plan = JSON.parse(JSON.stringify(plan));
    },
    savePlan:function (){
      const that =this;
      let updatejosn = JSON.stringify(this.plan);
      clientUtils.post("/system/plan/update", updatejosn, function (res) {
        if (res.data!=null) {
          // 编辑用户信息成功
          that.getPlan();
          that.back();
          that.$message({
            message: '数据更新成功',
            type: 'success'
          });
        } else {
          clientUtils.unknownErrorAlert(that, res.data.msg)
        }
      })
    },
    back:function(){
      this.Visible =false
    },
    getPlan:function (){
      const that = this
      clientUtils.get("/system/plan/findAll", function (res) {
        if (res.data != null) {
          console.log("数据："+res.data.data)
          that.planData=res.data.data
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
